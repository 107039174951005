export default function cutTextWployalty() {
    console.warn('zmiana tekstu');

    // Pobierz wszystkie elementy z klasami .wlr-discount-point i .wlr-text-color
    var elements = document.querySelectorAll(
        '.wlr-discount-point.wlr-text-color'
    );

    // Przejdź przez każdy element
    elements.forEach(function (element) {
        console.log(element);
        if (element.textContent.trim() === '100% nagroda') {
            console.log('usuwanie tekstu');
            element.textContent = '';
        }
    });
}
